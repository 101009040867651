import i18next from "i18next";
import { Theme, mergeStyleSets } from "@fluentui/react";
import { format, formatISO } from "date-fns";
import { toast } from "../components/FluentToast";
import { BackendError } from "../types/types";
import { FieldTypes, typeOfFormItems } from "./constants";
import moment from "moment";
import {
  inp_contract_type,
  inp_sicgroup,
  inp_vehicle_fuel,
  inp_vehicle_registration,
  insurerCasco,
  insurerMtpl,
  taxonomyFlat,
} from "./types";
import { COUNTIES_CITIES } from "types/countiesCities";

export const formatDateString = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "dd.MM.yyyy")
    : "";
};

export const stringToNumber = (str: string) => {
  let _str = str;
  _str = _str.replace(/\./g, "");
  _str = String(_str).replace(",", ".");
  return parseFloat(_str);
};

export const sanitizeFileName = (s: string) => {
  return s.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

export const getValueForServer = (type: string, value: any): any => {
  if (type === "date") {
    if (value === null || value === undefined) {
      return null;
    }
    return formatISO(value, { representation: "date" });
  }
  //
  else if (type === "number") {
    if (value === null || value === undefined) {
      return null;
    }
    return stringToNumber(value);
  }
  //
  else if (type === "selectlist") {
    if (value === null || value === undefined) {
      return null;
    }
    return value.value;
  } else if (type === "taxonomy") {
    if (value === null || value === undefined) {
      return null;
    }
    return value.value;
  } else if (type === "taxonomyapi") {
    if (value === null || value === undefined) {
      return null;
    }
    return value;
  }
  //
  else if (type === "taxonomylabel") {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
    //
    else {
      return value;
    }
  }
  //
  else if (type === "text") {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
    //
    else {
      return value;
    }
  }
  //
  else if (type === "checkbox") {
    return value;
  }
  return null;
};

export const toBase64 = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : undefined
          : undefined
      );
    };
    reader.readAsDataURL(file);
  });
};

export const getSelectStyles = (hasError: boolean, theme: Theme): any => {
  return {
    control: (p, s) => {
      return {
        ...p,
        border: 0,
        borderRadius: 0,
        borderBottom: "1px solid",
        borderBottomColor: s.isFocused
          ? hasError
            ? "red"
            : "#005aa1"
          : "#ccc",
        outline: "none",
        "&:hover": {
          borderBottomColor: s.isFocused
            ? hasError
              ? "red"
              : "#005aa1"
            : "#ccc",
        },
        backgroundColor: s.isDisabled ? theme.palette.white : undefined,
      };
    },
  };
};
export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};
export const getClasses = (theme, isDarkMode) =>
  mergeStyleSets({
    navigation: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "100%",
      "& .ms-Nav-groupContent": {
        marginBottom: "0",
      },
      ".ms-Nav-compositeLink.is-selected .ms-Button": {
        background: isDarkMode ? "#001321" : "#d0e7f8",
      },
    },
  });

export const fieldToTab = (field: string) => {
  switch (field) {
    case "inp_lessee_type":
      return "tab1";
    case "inp_contract_type":
      return "tab1";
    case "inp_policy_currency":
      return "tab1";
    case "inp_policy_value":
      return "tab1";
    case "inp_leasing_monthsinterval":
      return "tab1";
    case "inp_external_contractdate":
      return "tab1";
    case "inp_policy_payment":
      return "tab1";
    case "inp_start_date":
      return "tab1";
    case "mtpl_commission_broker_p":
      return "tab1";

    case "inp_vehicle_vin":
      return "tab2";
    case "inp_vehicle_brand":
      return "tab2";
    case "inp_vehicle_model":
      return "tab2";
    case "inp_vehicle_fuel":
      return "tab2";
    case "inp_vehicle_maximumgrossweight":
      return "tab2";
    case "inp_vehicle_places":
      return "tab2";
    case "inp_vehicle_kw":
      return "tab2";
    case "inp_vehicle_cubiccapacity":
      return "tab2";
    case "inp_vehicle_category":
      return "tab2";
    case "inp_vehicle_subcategory":
      return "tab2";
    case "inp_vehicle_construction":
      return "tab2";
    case "inp_vehicle_firstreg":
      return "tab2";
    case "inp_vehicle_state":
      return "tab2";
    case "inp_vehicle_carusage":
      return "tab2";
    case "inp_vehicle_fleet":
      return "tab2";
    case "inp_vehicle_registration":
      return "tab2";
    case "inp_vehicle_licenseplate":
      return "tab2";
    case "inp_vehicle_id":
      return "tab2";
    case "inp_vehicle_registration_expiration_date":
      return "tab2";

    case "inp_asistentarutiera":
      return "tab4";

    case "inp_discount_p":
      return "tab5";
    case "inp_increase_p":
      return "tab5";
    case "inp_discount_insurer":
      return "tab5";

    case "inp_lessee_uid":
      return "tab6";
    case "inp_lessee_company_name":
      return "tab6";
    case "inp_caen":
      return "tab6";
    case "inp_lessee_compreg":
      return "tab6";
    case "inp_lessee_lastname":
      return "tab6";
    case "inp_lessee_firstname":
      return "tab6";
    case "inp_licence_date":
      return "tab6";
    case "inp_sicgroup":
      return "tab6";
    case "inp_lessee_county":
      return "tab6";
    case "inp_lessee_city":
      return "tab6";
    case "inp_lessee_street":
      return "tab6";
    case "inp_lessee_housenr":
      return "tab6";
    case "inp_lessee_building":
      return "tab6";
    case "inp_lessee_entrance":
      return "tab6";
    case "inp_lessee_door":
      return "tab6";
    case "inp_lessee_floor":
      return "tab6";
    case "inp_lessee_mobile":
      return "tab6";
    case "inp_lessee_email":
      return "tab6";

    case "insurerCasco":
      return "tab1";
    case "leasingctrno":
      return "tab1";

    case "insurerMtpl":
      return "tab2";
    case "months":
      return "tab2";
    case "decontare_directa":
      return "tab2";
    case "leasingctrno_mtpl":
      return "tab2";

    case "validFrom":
      return "tab1";
    case "deliveryType":
      return "tab1";

    case "paymentDate":
      return "tab2";
    case "confirmationForMtplPolicyIssuing":
      return "tab2";

    default:
      return "tab9";
  }
};

export const handleAxiosError = (err?: any) => {
  if (err?.isAxiosError) {
    const response = err.response;
    if (response) {
      const responseData = err.response.data;
      if (responseData) {
        const env = process.env.REACT_APP_ENVIRONMENT!;
        const developmentEnvironments = ["L", "D", "T"];
        const isDevOrTestEnv = developmentEnvironments.includes(env);
        if (Array.isArray(responseData)) {
          responseData.forEach((e: BackendError) => {
            const translatedMessageVariables = e.messageVariables.map(
              (item: string) => {
                return i18next.t(item);
              }
            );
            let message = e.messageCode;
            if (isDevOrTestEnv) {
              message = e?.exceptionMessage || e?.formatedMessage;
              toast.error(i18next.t(message));
            } else {
              toast.error(i18next.t(message, translatedMessageVariables));
            }
          });
        }
        //
        else {
          let message = responseData.messageCode;
          if (message) {
            if (isDevOrTestEnv) {
              message =
                responseData?.exceptionMessage || responseData?.formatedMessage;
            }

            const translatedMessageVariables =
              responseData.messageVariables.map((item: string) => {
                return i18next.t(item);
              });
            toast.error(i18next.t(message, translatedMessageVariables));
          } else {
            if (responseData.Message) {
              toast.error(responseData.Message);
            } else {
              toast.error(responseData);
            }
          }
        }
      } else {
        toast.error(err?.toString());
      }
    } else {
      toast.error(err?.toString());
    }
  } else {
    toast.error(err?.toString());
  }
};
export const toNum = (value: string) => {
  return value && value !== ""
    ? Number.parseFloat(value) //.replaceAll(".", "").replaceAll(",", "."))
    : null;
};

export const prepareFormItemsForSubmit = (data, romanianTranslations) => {
  let payload = { ...data };

  Object.entries(typeOfFormItems).forEach(([key, value]) => {
    if (value === FieldTypes.NUMBER) {
      payload[key] =
        payload[key] && payload[key] !== ""
          ? Number.parseFloat(payload[key].replaceAll(",", "."))
          : null;
      // } else if (value === FieldTypes.ARRAY) {
      //   payload[key] = payload[key] ? JSON.stringify(payload[key]) : null;
    } else if (value === FieldTypes.INTEGER) {
      payload[key] =
        payload[key] && payload[key] !== ""
          ? Number.parseInt(payload[key].replaceAll(",", "."))
          : null;
    } else if (value === FieldTypes.BOOLEAN) {
      payload[key] =
        payload[key] && (payload[key] === true || payload[key] === "true")
          ? true
          : false;
    } else if (value === FieldTypes.DATE) {
      payload[key] =
        payload[key] && payload[key] !== ""
          ? moment(payload[key]).format("YYYY-MM-DD")
          : null;
    } else if (value === FieldTypes.DATEISO) {
      payload[key] =
        payload[key] && payload[key] !== ""
          ? moment(payload[key]).toISOString()
          : null;
    } else if (value === FieldTypes.TAXONOMY) {
      if (payload[key]) {
        let romanianTranslation = romanianTranslations[payload[key]];
        if (key === "insurerCasco" || key === "insurerMtpl") {
          romanianTranslation = romanianTranslation.toLowerCase();
        }
        if (romanianTranslation) {
          payload[key] = romanianTranslation;
        } else {
          payload[key] = payload[key];
        }
      } else {
        payload[key] = null;
      }
    }
  });

  if (payload["inp_policy_currency"] === null) {
    payload["inp_policy_currency"] = "";
  }
  return payload;
};

export const transformVehiclePayload = (data, t) => {
  let payload = { ...data };
  Object.entries(typeOfFormItems).forEach(([key, value]) => {
    if (value === FieldTypes.NUMBER) {
      if (typeof payload[key] === "string") {
        payload[key] =
          payload[key] && payload[key] !== ""
            ? (payload[key] + "").replaceAll(".", ",")
            : "";
      } else {
        payload[key]
          ? (payload[key] = payload[key] + "")
          : (payload[key] = null);
      }
    } else if (value === FieldTypes.INTEGER) {
      payload[key] =
        payload[key] && payload[key] !== "" ? payload[key] + "" : "";
    } else if (value === FieldTypes.BOOLEAN) {
      payload[key] = payload[key] ? true : false;
    } else if (value === FieldTypes.DATE) {
      payload[key] =
        payload[key] && payload[key] !== ""
          ? moment(payload[key]).toDate() //.format("YYYY-MM-DD")
          : null;
    } else if (value === FieldTypes.DATEISO) {
      payload[key] =
        payload[key] && payload[key] !== ""
          ? moment(payload[key]).toDate() //.toISOString();
          : null;
    } else if (value === FieldTypes.TAXONOMY) {
      const code = taxonomyFlat[key][payload[key]];
      // console.log(
      //   "code",
      //   code,
      //   key,
      //   taxonomyFlat[key][payload[key]],
      //   payload[key],
      //   taxonomyFlat[key]
      // );

      if (code) {
        if (key === "comparisonType") {
          //payload[key] = payload[key];
        } else {
          payload[key] = code;
        }
      } else {
        payload[key] = null;
      }
    }
  });
  return payload;
};

export const prepareFormReturnValue = (retVal, t) => {
  let updatedRetVal = {};
  for (const key in retVal) {
    if (
      typeOfFormItems[key] === FieldTypes.NUMBER ||
      typeOfFormItems[key] === FieldTypes.INTEGER
    ) {
      updatedRetVal = {
        ...updatedRetVal,
        [key]:
          typeof retVal[key] !== "undefined" && retVal[key] !== null
            ? retVal[key] + "" //.replaceAll(",", "").replaceAll(".", ",")
            : null,
      };
    } else if (typeOfFormItems[key] === FieldTypes.DATE) {
      updatedRetVal = {
        ...updatedRetVal,
        [key]: retVal[key] ? moment(retVal[key]).toDate() : null,
      };
    } else if (typeOfFormItems[key] === FieldTypes.DATEISO) {
      updatedRetVal = {
        ...updatedRetVal,
        [key]: retVal[key] ? moment(retVal[key]).toDate() : null,
      };
    } else if (typeOfFormItems[key] === FieldTypes.TAXONOMY) {
      const code = taxonomyFlat[key][retVal[key]];
      if (code) {
        if (key === "comparisonType") {
          updatedRetVal = {
            ...updatedRetVal,
            [key]: {
              value: retVal[key],
              label: t(code),
            },
          };
        } else if (
          key === "inp_vehicle_construction" ||
          key === "inp_vehicle_firstreg"
        ) {
          updatedRetVal = {
            ...updatedRetVal,
            [key]: {
              value: retVal[key],
              label: retVal[key],
            },
          };
        } else {
          updatedRetVal = {
            ...updatedRetVal,
            [key]: {
              value: code,
              label: t(code),
            },
          };
        }
      }
    } else {
      updatedRetVal = {
        ...updatedRetVal,
        [key]: retVal[key],
      };
    }
  }
  return updatedRetVal;
};

export function numberValidation(
  yup: any,
  t: any,
  required: boolean,
  min: number | null,
  max: number | null
) {
  let retVal = yup
    .string()
    .nullable()
    .test("is-number", "Must be a number", (value) => {
      if (value === null || value === "") {
        return true;
      }

      const parsedNumber = Number(value);
      return !isNaN(parsedNumber);
    });

  if (required) {
    retVal = retVal.required();
  }
  if (min !== null) {
    retVal = retVal.test(
      "min",
      t("greco.form.errors.number.greaterorequal", { min }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value //.replaceAll(/\s/g, "").replace(",", ".")
        );
        return parsedNumber >= min;
      }
    );
  }
  if (max !== null) {
    retVal = retVal.test(
      "max",
      t("greco.form.errors.number.lessorequal", { max }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value //.replaceAll(/\s/g, "").replace(",", ".")
        );
        return parsedNumber <= max;
      }
    );
  }
  return retVal;
}

export function isNotNullOrUndefined(value: any): boolean {
  return value !== null && value !== undefined;
}

export const setCityOptions = (dataValue, t) => {
  return COUNTIES_CITIES[t(dataValue).toUpperCase()]
    ? COUNTIES_CITIES[t(dataValue).toUpperCase()].map((city) => ({
        label: city,
        value: city,
      }))
    : [];
};
export const setSubcategoryOptions = (dataValue, t) => {
  const value = dataValue;
  if (value === null) return [];
  if (value === "bcr.Category.TOURISM") {
    return [
      {
        label: t("bcr.SubCategory.AUTOTURISM"),
        value: "bcr.SubCategory.AUTOTURISM",
      },
      {
        label: t("bcr.SubCategory.AUTOMOBIL_MIXED"),
        value: "bcr.SubCategory.AUTOMOBIL_MIXED",
      },
      {
        label: t("bcr.SubCategory.OFF-ROAD"),
        value: "bcr.SubCategory.OFF-ROAD",
      },
    ];
  }

  if (value === "bcr.Category.COMERCIAL") {
    return [
      { label: t("bcr.SubCategory.DUMPER"), value: "bcr.SubCategory.DUMPER" },
      {
        label: t("bcr.SubCategory.MIXER_TRUCK"),
        value: "bcr.SubCategory.MIXER_TRUCK",
      },
      { label: t("bcr.SubCategory.LORRY"), value: "bcr.SubCategory.LORRY" },
      { label: t("bcr.SubCategory.TANK"), value: "bcr.SubCategory.TANK" },
      {
        label: t("bcr.SubCategory.AUTOEXCAVATOR"),
        value: "bcr.SubCategory.AUTOEXCAVATOR",
      },
      {
        label: t("bcr.SubCategory.MOTORVAN"),
        value: "bcr.SubCategory.MOTORVAN",
      },
      {
        label: t("bcr.SubCategory.SELF_GRADER"),
        value: "bcr.SubCategory.SELF_GRADER",
      },
      {
        label: t("bcr.SubCategory.LOADING_MEMBER"),
        value: "bcr.SubCategory.LOADING_MEMBER",
      },
      { label: t("bcr.SubCategory.CRANE"), value: "bcr.SubCategory.CRANE" },
      { label: t("bcr.SubCategory.TRUCK"), value: "bcr.SubCategory.TRUCK" },
      {
        label: t("bcr.SubCategory.SELF-SPECIALIZED"),
        value: "bcr.SubCategory.SELF-SPECIALIZED",
      },
      { label: t("bcr.SubCategory.VAN"), value: "bcr.SubCategory.VAN" },
      { label: t("bcr.SubCategory.TIPPER"), value: "bcr.SubCategory.TIPPER" },
      {
        label: t("bcr.SubCategory.BACKHOE"),
        value: "bcr.SubCategory.BACKHOE",
      },
      {
        label: t("bcr.SubCategory.TRUCK_CAMION"),
        value: "bcr.SubCategory.TRUCK_CAMION",
      },
      { label: "bcr.SubCategory.FURGON", value: "bcr.SubCategory.FURGON" },
    ];
  }

  if (value === "bcr.Category.TRANSPORTING") {
    return [
      { label: t("bcr.SubCategory.BUS"), value: "bcr.SubCategory.BUS" },
      {
        label: t("bcr.SubCategory.ARTICULATED_BUS"),
        value: "bcr.SubCategory.ARTICULATED_BUS",
      },
      {
        label: t("bcr.SubCategory.BUS_SPECIAL"),
        value: "bcr.SubCategory.BUS_SPECIAL",
      },
      {
        label: t("bcr.SubCategory.AUTOCAR"),
        value: "bcr.SubCategory.AUTOCAR",
      },
      {
        label: t("bcr.SubCategory.MINIBUS"),
        value: "bcr.SubCategory.MINIBUS",
      },
      { label: t("bcr.SubCategory.TRAM"), value: "bcr.SubCategory.TRAM" },
      {
        label: t("bcr.SubCategory.TROLLEYBUS"),
        value: "bcr.SubCategory.TROLLEYBUS",
      },
    ];
  }

  if (value === "bcr.Category.FARM") {
    return [
      {
        label: t("bcr.SubCategory.AUTOTRACTOR"),
        value: "bcr.SubCategory.AUTOTRACTOR",
      },
      {
        label: t("bcr.SubCategory.AUTO_TRACTOR_WITH_ITS"),
        value: "bcr.SubCategory.AUTO_TRACTOR_WITH_ITS",
      },
      {
        label: "bcr.SubCategory.CAP_TRACTOR",
        value: "bcr.SubCategory.CAP_TRACTOR",
      },
    ];
  }

  if (value === "bcr.Category.CAMP") {
    return [
      { label: t("bcr.SubCategory.CAMP"), value: "bcr.SubCategory.CAMP" },
    ];
  }

  if (value === "bcr.Category.MOTORCYCLES") {
    return [
      { label: t("bcr.SubCategory.ATV"), value: "bcr.SubCategory.ATV" },
      { label: t("bcr.SubCategory.MOPED"), value: "bcr.SubCategory.MOPED" },
      {
        label: t("bcr.SubCategory.MOTORCYCLE"),
        value: "bcr.SubCategory.MOTORCYCLE",
      },
      {
        label: t("bcr.SubCategory.MOTOR_SCOOTER"),
        value: "bcr.SubCategory.MOTOR_SCOOTER",
      },
    ];
  }

  if (value === "bcr.Category.TRAILERS_SEMI-TRAILERS") {
    return [
      { label: t("bcr.SubCategory.TOW"), value: "bcr.SubCategory.TOW" },
      {
        label: t("bcr.SubCategory.CAMPER_VAN"),
        value: "bcr.SubCategory.CAMPER_VAN",
      },
      {
        label: t("bcr.SubCategory.SEMI_TOW"),
        value: "bcr.SubCategory.SEMI_TOW",
      },
    ];
  }

  if (value === "bcr.Category.TRAILERS_SEMI-TRAILERS_CISTERN") {
    return [
      { label: t("bcr.SubCategory.TOW"), value: "bcr.SubCategory.TOW" },
      {
        label: t("bcr.SubCategory.CAMPER_VAN"),
        value: "bcr.SubCategory.CAMPER_VAN",
      },
      {
        label: t("bcr.SubCategory.SEMI_TOW"),
        value: "bcr.SubCategory.SEMI_TOW",
      },
    ];
  }

  if (value === "bcr.Category.MIXEDVEHICLE") {
    return [
      { label: t("bcr.SubCategory.VAN"), value: "bcr.SubCategory.VAN" },
      { label: t("bcr.SubCategory.TANK"), value: "bcr.SubCategory.TANK" },
      {
        label: t("bcr.SubCategory.MACHINERY"),
        value: "bcr.SubCategory.MACHINERY",
      },
    ];
  }

  if (value === "bcr.Category.AGRICULTURAL") {
    return [
      {
        label: "bcr.SubCategory.MACHINERY",
        value: "bcr.SubCategory.MACHINERY",
      },
    ];
  }

  if (value === "bcr.Category.AGRICULTURAL_NON-PROPELLED") {
    return [
      {
        label: t("bcr.SubCategory.MACHINERY"),
        value: "bcr.SubCategory.MACHINERY",
      },
    ];
  }

  if (value === "bcr.Category.TRACTOR_ROAD") {
    return [
      {
        label: t("bcr.SubCategory.ROAD_TRACTOR"),
        value: "bcr.SubCategory.ROAD_TRACTOR",
      },
      {
        label: t("bcr.SubCategory.AGRICULTURE_TRACTOR"),
        value: "bcr.SubCategory.AGRICULTURE_TRACTOR",
      },
    ];
  }

  if (value === "bcr.Category.REFIGERATED_TRAILERS") {
    return [
      { label: t("bcr.SubCategory.TOW"), value: "bcr.SubCategory.TOW" },
      {
        label: t("bcr.SubCategory.CAMPER_VAN"),
        value: "bcr.SubCategory.CAMPER_VAN",
      },
      {
        label: t("bcr.SubCategory.SEMI_TOW"),
        value: "bcr.SubCategory.SEMI_TOW",
      },
    ];
  }

  return [];
};

export const setValueOfSubCategory = (value, t) => {
  if (value === null) return null;
  if (value === "bcr.Category.TOURISM") {
    return {
      value: "bcr.SubCategory.AUTOTURISM",
      label: t("bcr.SubCategory.AUTOTURISM"),
    };
  }

  if (value === "bcr.Category.COMERCIAL") {
    return null;
  }

  if (value === "bcr.Category.TRANSPORTING") {
    return null;
  }

  if (value === "bcr.Category.FARM") {
    return {
      value: "bcr.SubCategory.AUTOTRACTOR",
      label: t("bcr.SubCategory.AUTOTRACTOR"),
    };
  }

  if (value === "bcr.Category.CAMP") {
    return {
      value: "bcr.SubCategory.CAMP",
      label: t("bcr.SubCategory.CAMP"),
    };
  }

  if (value === "bcr.Category.MOTORCYCLES") {
    return null;
  }

  if (value === "bcr.Category.TRAILERS_SEMI-TRAILERS") {
    return null;
  }

  if (value === "bcr.Category.TRAILERS_SEMI-TRAILERS_CISTERN") {
    return null;
  }

  if (value === "bcr.Category.MIXEDVEHICLE") {
    return null;
  }

  if (value === "bcr.Category.AGRICULTURAL") {
    return {
      value: "bcr.SubCategory.MACHINERY",
      label: t("bcr.SubCategory.MACHINERY"),
    };
  }

  if (value === "bcr.Category.AGRICULTURAL_NON-PROPELLED") {
    return {
      value: "bcr.SubCategory.MACHINERY",
      label: t("bcr.SubCategory.MACHINERY"),
    };
  }

  if (value === "bcr.Category.TRACTOR_ROAD") {
    return null;
  }

  if (value === "bcr.Category.REFIGERATED_TRAILERS") {
    return null;
  }

  if (!value) {
    return null;
  }
};

export const setInsurerCascoOptions = (data, t) => {
  let ddl = [];
  ddl.push({
    label: t(insurerCasco["omniasig"]),
    value: insurerCasco["omniasig"],
  });
  ddl.push({ label: t(insurerCasco["uniqa"]), value: insurerCasco["uniqa"] });
  if (data.comparisonType?.value !== "cp") {
    if (
      data.offerRequestType === "F100004" ||
      data.offerRequestType === "F100018"
    ) {
      ddl = [];
      if (data.omniasig_premium !== null) {
        ddl.push({
          label: t(insurerCasco["omniasig"]),
          value: insurerCasco["omniasig"],
        });
      }
      if (data.asirom_premium !== null) {
        ddl.push({
          label: t(insurerCasco["asirom"]),
          value: insurerCasco["asirom"],
        });
      }
      if (data.allianz_premium !== null) {
        ddl.push({
          label: t(insurerCasco["allianz"]),
          value: insurerCasco["allianz"],
        });
      }
      if (data.uniqa_premium !== null) {
        ddl.push({
          label: t(insurerCasco["uniqa"]),
          value: insurerCasco["uniqa"],
        });
      }
      if (data.generali_premium !== null) {
        ddl.push({
          label: t(insurerCasco["generali"]),
          value: insurerCasco["generali"],
        });
      }
      if (data.groupama_premium !== null) {
        ddl.push({
          label: t(insurerCasco["groupama"]),
          value: insurerCasco["groupama"],
        });
      }
    }
  } else {
    if (data.asirom_premium !== null) {
      ddl.push({
        label: t(insurerCasco["asirom"]),
        value: insurerCasco["asirom"],
      });
    }
  }
  return ddl;
};

export const setInsurerMtplOptions = (data, t) => {
  let ddl = [];
  if (
    data.omniasig_premium_1 !== null ||
    data.omniasig_premium_1d !== null ||
    data.omniasig_premium_6 !== null ||
    data.omniasig_premium_6d !== null ||
    data.omniasig_premium_12 !== null ||
    data.omniasig_premium_12d !== null
  ) {
    ddl.push({
      label: t(insurerMtpl["omniasig"]),
      value: insurerMtpl["omniasig"],
    });
  }
  if (
    data.asirom_premium_1 !== null ||
    data.asirom_premium_1d !== null ||
    data.asirom_premium_6 !== null ||
    data.asirom_premium_6d !== null ||
    data.asirom_premium_12 !== null ||
    data.asirom_premium_12d !== null
  ) {
    ddl.push({
      label: t(insurerMtpl["asirom"]),
      value: insurerMtpl["asirom"],
    });
  }
  if (
    data.allianz_premium_1 !== null ||
    data.allianz_premium_1d !== null ||
    data.allianz_premium_6 !== null ||
    data.allianz_premium_6d !== null ||
    data.allianz_premium_12 !== null ||
    data.allianz_premium_12d !== null
  ) {
    ddl.push({
      label: t(insurerMtpl["allianz"]),
      value: insurerMtpl["allianz"],
    });
  }
  if (
    data.generali_premium_1 !== null ||
    data.generali_premium_1d !== null ||
    data.generali_premium_6 !== null ||
    data.generali_premium_6d !== null ||
    data.generali_premium_12 !== null ||
    data.generali_premium_12d !== null
  ) {
    ddl.push({
      label: t(insurerMtpl["generali"]),
      value: insurerMtpl["generali"],
    });
  }
  if (
    data.groupama_premium_1 !== null ||
    data.groupama_premium_1d !== null ||
    data.groupama_premium_6 !== null ||
    data.groupama_premium_6d !== null ||
    data.groupama_premium_12 !== null ||
    data.groupama_premium_12d !== null
  ) {
    ddl.push({
      label: t(insurerMtpl["groupama"]),
      value: insurerMtpl["groupama"],
    });
  }
  if (
    data.grawe_premium_1 !== null ||
    data.grawe_premium_1d !== null ||
    data.grawe_premium_6 !== null ||
    data.grawe_premium_6d !== null ||
    data.grawe_premium_12 !== null ||
    data.grawe_premium_12d !== null
  ) {
    ddl.push({
      label: t(insurerMtpl["grawe"]),
      value: insurerMtpl["grawe"],
    });
  }

  if (data.comparisonType?.value === "rcacp") {
    ddl = [];

    if (
      data.omniasig_premium_1 !== null ||
      data.omniasig_premium_1d !== null ||
      data.omniasig_premium_6 !== null ||
      data.omniasig_premium_6d !== null ||
      data.omniasig_premium_12 !== null ||
      data.omniasig_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["omniasig"]),
        value: insurerMtpl["omniasig"],
      });
    }
  } else if (data.offerRequestType === "F100018") {
    ddl = [];
    if (
      data.omniasig_premium_1 !== null ||
      data.omniasig_premium_1d !== null ||
      data.omniasig_premium_6 !== null ||
      data.omniasig_premium_6d !== null ||
      data.omniasig_premium_12 !== null ||
      data.omniasig_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["omniasig"]),
        value: insurerMtpl["omniasig"],
      });
    }
    if (
      data.asirom_premium_1 !== null ||
      data.asirom_premium_1d !== null ||
      data.asirom_premium_6 !== null ||
      data.asirom_premium_6d !== null ||
      data.asirom_premium_12 !== null ||
      data.asirom_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["asirom"]),
        value: insurerMtpl["asirom"],
      });
    }
    if (
      data.allianz_premium_1 !== null ||
      data.allianz_premium_1d !== null ||
      data.allianz_premium_6 !== null ||
      data.allianz_premium_6d !== null ||
      data.allianz_premium_12 !== null ||
      data.allianz_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["allianz"]),
        value: insurerMtpl["allianz"],
      });
    }
    if (
      data.generali_premium_1 !== null ||
      data.generali_premium_1d !== null ||
      data.generali_premium_6 !== null ||
      data.generali_premium_6d !== null ||
      data.generali_premium_12 !== null ||
      data.generali_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["generali"]),
        value: insurerMtpl["generali"],
      });
    }
    if (
      data.groupama_premium_1 !== null ||
      data.groupama_premium_1d !== null ||
      data.groupama_premium_6 !== null ||
      data.groupama_premium_6d !== null ||
      data.groupama_premium_12 !== null ||
      data.groupama_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["groupama"]),
        value: insurerMtpl["groupama"],
      });
    }
    if (
      data.grawe_premium_1 !== null ||
      data.grawe_premium_1d !== null ||
      data.grawe_premium_6 !== null ||
      data.grawe_premium_6d !== null ||
      data.grawe_premium_12 !== null ||
      data.grawe_premium_12d !== null
    ) {
      ddl.push({
        label: t(insurerMtpl["grawe"]),
        value: insurerMtpl["grawe"],
      });
    }
  }
  return ddl;
};

export const setVehicleRegistrationOptions = (data, t) => {
  let ddlValues = [
    {
      label: t(inp_vehicle_registration["in vederea inmatricularii"]),
      value: inp_vehicle_registration["in vederea inmatricularii"],
    },
  ];
  if (data.offerRequestType !== "F100018") {
    ddlValues = [
      {
        label: t(inp_vehicle_registration["inmatriculat"]),
        value: inp_vehicle_registration["inmatriculat"],
      },
      {
        label: t(inp_vehicle_registration["inregistrat"]),
        value: inp_vehicle_registration["inregistrat"],
      },
      {
        label: t(inp_vehicle_registration["in vederea inmatricularii"]),
        value: inp_vehicle_registration["in vederea inmatricularii"],
      },
      {
        label: t(inp_vehicle_registration["in vederea inregistrarii"]),
        value: inp_vehicle_registration["in vederea inregistrarii"],
      },
    ];
  }
  return ddlValues;
};

export const setSicGroupOptions = (data, t) => {
  var ddlValues = [];
  if (
    data?.inp_contract_type === inp_contract_type["Contract de credit"] ||
    data?.inp_contract_type === "Contract de credit"
  ) {
    ddlValues = [
      {
        label: t(inp_sicgroup["SC nefinanciare - S.A."]),
        value: inp_sicgroup["SC nefinanciare - S.A."],
      },
      {
        label: t(inp_sicgroup["SC nefinanciare - S.R.L."]),
        value: inp_sicgroup["SC nefinanciare - S.R.L."],
      },
      {
        label: t(inp_sicgroup["SC nefinanciare - P.F.A."]),
        value: inp_sicgroup["SC nefinanciare - P.F.A."],
      },
      {
        label: t(inp_sicgroup["SC nefinanciare - P.F.I."]),
        value: inp_sicgroup["SC nefinanciare - P.F.I."],
      },
      {
        label: t(inp_sicgroup["alte SC nefinanciare"]),
        value: inp_sicgroup["alte SC nefinanciare"],
      },
      {
        label: t(inp_sicgroup["banci si cooperative de credit"]),
        value: inp_sicgroup["banci si cooperative de credit"],
      },
      {
        label: t(inp_sicgroup["alti intermediari financiari"]),
        value: inp_sicgroup["alti intermediari financiari"],
      },
      {
        label: t(inp_sicgroup["institutii guvernamentale"]),
        value: inp_sicgroup["institutii guvernamentale"],
      },
      {
        label: t(inp_sicgroup["regii autonome"]),
        value: inp_sicgroup["regii autonome"],
      },
    ];
  } else if (
    data?.inp_contract_type ===
      inp_contract_type["Contract de leasing financiar"] ||
    data?.inp_contract_type === "Contract de leasing financiar"
  ) {
    ddlValues = [
      {
        label: t(inp_sicgroup["intermediari financiari - leasing"]),
        value: inp_sicgroup["intermediari financiari - leasing"],
      },
    ];
  }
  return ddlValues;
};

export const setFuelTypeOptions = (data, t) => {
  let ddl = [
    {
      label: t(inp_vehicle_fuel["benzina"]),
      value: inp_vehicle_fuel["benzina"],
    },
    {
      label: t(inp_vehicle_fuel["motorina"]),
      value: inp_vehicle_fuel["motorina"],
    },
    {
      label: t(inp_vehicle_fuel["electric"]),
      value: inp_vehicle_fuel["electric"],
    },
    {
      label: t(inp_vehicle_fuel["benzina si gpl"]),
      value: inp_vehicle_fuel["benzina si gpl"],
    },
    {
      label: t(inp_vehicle_fuel["benzina si alcool"]),
      value: inp_vehicle_fuel["benzina si alcool"],
    },
    {
      label: t(inp_vehicle_fuel["hybrid benzina"]),
      value: inp_vehicle_fuel["hybrid benzina"],
    },
    {
      label: t(inp_vehicle_fuel["hybrid motorina"]),
      value: inp_vehicle_fuel["hybrid motorina"],
    },
    { label: t(inp_vehicle_fuel["fara"]), value: inp_vehicle_fuel["fara"] },
    { label: t(inp_vehicle_fuel["altul"]), value: inp_vehicle_fuel["altul"] },
  ];
  if (data.offerRequestType === "F100018") {
    ddl = [
      {
        label: t(inp_vehicle_fuel["benzina"]),
        value: inp_vehicle_fuel["benzina"],
      },
      {
        label: t(inp_vehicle_fuel["motorina"]),
        value: inp_vehicle_fuel["motorina"],
      },
      {
        label: t(inp_vehicle_fuel["electric"]),
        value: inp_vehicle_fuel["electric"],
      },
      {
        label: t(inp_vehicle_fuel["hybrid benzina"]),
        value: inp_vehicle_fuel["hybrid benzina"],
      },
      {
        label: t(inp_vehicle_fuel["hybrid motorina"]),
        value: inp_vehicle_fuel["hybrid motorina"],
      },
    ];
  }
  return ddl;
};

export const setContractTypeOptions = (data, t) => {
  const value = data.offerRequestType;
  if (value === "F100018") {
    return [
      {
        label: t("bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT"),
        value: "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT",
      },

      {
        label: t("bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT"),
        value: "bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT",
      },
    ];
  } else {
    return [
      {
        label: t("bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT"),
        value: "bcr.Vehicle.CONTRACT_TYPE_FINANCIAL_LEASING_AGREEMENT",
      },

      {
        label: t("bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT"),
        value: "bcr.Vehicle.CONTRACT_TYPE_CREDIT_CONTRACT",
      },
    ];
  }
};
